<template>
  <div>
    <v-container :class="{ 'pa-0': $vuetify.breakpoint.smAndDown }">
      <v-card class="msa-list mt-3 pt-3">
        <v-row v-if="$vuetify.breakpoint.mdAndUp">
          <v-col class="pt-0 pb-0" style="overflow: hidden">
            <v-text-field
              id="request-history--search-field"
              v-model="searchString"
              aria-label="filter access requests"
              class="data-table--search-field"
              hide-details
              label=""
              outlined
              placeholder="Search history"
              prepend-inner-icon="search"
              @input="onSearchChangeDebounced"
            />
          </v-col>
        </v-row>
        <div v-if="!hasHistory" class="center-content">
          <span v-if="isLoading">Loading...</span>
          <span v-if="!isLoading && searchString">
            No history found for <b>{{ searchString }}</b
            >.</span
          >
          <span v-if="!isLoading && !searchString">No history found.</span>
        </div>

        <div
          v-if="hasHistory"
          id="request-history--card-expansion-list"
          class="pt-4"
        >
          <v-expansion-panels v-model="expandedPanelIndex" accordion>
            <v-expansion-panel v-for="(item, i) in history" :key="i">
              <v-expansion-panel-header
                :aria-expanded="isExpanded(i) ? 'true' : 'false'"
                class="py-1"
              >
                <v-row v-if="$vuetify.breakpoint.mdAndUp">
                  <v-col class="mr-2" cols="3">
                    <InitialsIcon
                      :given-name="item.requestorFirstName"
                      :surname="item.requestorLastName"
                      class="mr-2"
                    />
                    <span>{{
                      (item.requestorFirstName + " " + item.requestorLastName)
                        | capitalise
                    }}</span>
                  </v-col>
                  <v-col>
                    <div class="d-flex align-center">
                      <AppIcon
                        v-if="isApp(item)"
                        :app-name="item.application.icon"
                      />
                      <AppIconRole
                        v-if="!isApp(item)"
                        :role-code="item.roleCode"
                      />
                      <div class="px-2" style="line-height: 1.5rem">
                        <span>{{ item.requestDescription }}</span>
                      </div>
                    </div>
                  </v-col>
                  <v-col class="d-flex align-center justify-end">
                    <div class="mr-6">
                      <span class="text-right">{{
                        item.approvalTimestamp | date
                      }}</span>
                    </div>
                    <div class="mr-6">
                      <RequestStatusPill
                        :approval-status="item.approvalStatus"
                      />
                    </div>
                  </v-col>
                </v-row>

                <!-- mobile expansion header -->
                <div
                  v-if="$vuetify.breakpoint.smAndDown"
                  class="d-flex py-2 align-center"
                  style="min-width: 100%"
                >
                  <div>
                    <InitialsIcon
                      :given-name="item.requestorFirstName"
                      :surname="item.requestorLastName"
                      class="mr-2"
                    />
                  </div>
                  <div class="flex-fluid-clipped">
                    <div>
                      <span class="body-1">
                        {{
                          (item.requestorFirstName +
                            " " +
                            item.requestorLastName)
                            | capitalise
                        }}
                      </span>
                    </div>
                    <div v-if="!isExpanded(i)" class="body-2">
                      {{ item.requestDescription }}
                    </div>
                  </div>
                  <div class="d-flex flex-column align-center justify-center">
                    <div>
                      <RequestStatusPill
                        :approval-status="item.approvalStatus"
                      />
                    </div>
                    <div v-if="!isExpanded(i)" class="mt-2">
                      <span style="float: right">{{
                        item.approvalTimestamp | date
                      }}</span>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <!-- Desktop expansion content -->
                <div
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="text-left mt-3 ml-3"
                >
                  <v-row>
                    <v-col cols="6">
                      <ReadOnlyField
                        :value="item.requestorComments"
                        class="mb-4"
                        label="Requester comment"
                      />
                      <ReadOnlyField
                        :value="item.approverComments"
                        label="Authoriser comment"
                      />
                    </v-col>

                    <v-col cols="3">
                      <ReadOnlyField
                        :value="item.requestedTimestamp"
                        class="mb-4"
                        filter="timeDate"
                        label="Date requested"
                      />
                      <ReadOnlyField
                        :value="item.accessRequested"
                        label="Access requested"
                      />
                    </v-col>

                    <v-col cols="3">
                      <ReadOnlyField
                        :value="item.approvalTimestamp"
                        class="mb-4"
                        filter="timeDate"
                        label="Date actioned"
                      />
                      <ReadOnlyField
                        :value="
                          item.approverFirstName + ' ' + item.approverLastName
                        "
                        label="Authoriser"
                      />
                    </v-col>
                  </v-row>
                </div>

                <!-- Mobile expansion content -->
                <div v-if="$vuetify.breakpoint.smAndDown" class="text-left">
                  <div class="d-flex align-center mt-2 ml-3">
                    <AppIcon
                      v-if="isApp(item)"
                      :app-name="item.application.icon"
                    />
                    <AppIconRole
                      v-if="!isApp(item)"
                      :role-code="item.roleCode"
                    />
                    <div class="px-2" style="line-height: 1.5rem">
                      <span>{{ item.requestDescription }}</span>
                    </div>
                  </div>

                  <v-row class="mt-3">
                    <v-col cols="6">
                      <ReadOnlyField
                        :value="item.requestedTimestamp"
                        filter="date"
                        label="Date requested"
                      />
                    </v-col>
                    <v-col cols="6">
                      <ReadOnlyField
                        :value="item.approvalTimestamp"
                        filter="date"
                        label="Date actioned"
                      />
                    </v-col>
                  </v-row>

                  <v-row class="mt-3">
                    <v-col cols="6">
                      <ReadOnlyField
                        :value="
                          item.approverFirstName + ' ' + item.approverLastName
                        "
                        label="Authoriser"
                      />
                    </v-col>
                    <v-col cols="6">
                      <ReadOnlyField
                        :value="item.accessRequested"
                        label="Access requested"
                      />
                    </v-col>
                  </v-row>

                  <div class="mt-3">
                    <ReadOnlyField
                      :value="item.approverComments"
                      label="Authoriser comment"
                    />
                  </div>

                  <div class="mt-3">
                    <ReadOnlyField
                      :value="item.requestorComments"
                      label="Requester comment"
                    />
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AppIcon, InitialsIcon } from "@nswdoe/doe-ui-core";
import RequestStatusPill from "@/components/RequestStatusPill";
import AppIconRole from "@/components/AppIconRole";
import ReadOnlyField from "@/components/ReadOnlyField";
import { date, timeDate } from "@/filters/date";
import searchDebounce from "@/mixins/searchDebounce";

export default {
  components: {
    AppIcon,
    InitialsIcon,
    AppIconRole,
    ReadOnlyField,
    RequestStatusPill
  },
  data() {
    return {
      searchString: "",
      expandedPanelIndex: null
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
      historyFiltered: "historyFiltered",
      locationId: "selectedSchoolId"
    }),
    history() {
      return this.historyFiltered(this.locationId);
    },
    hasHistory() {
      return this.history && this.history.length > 0;
    }
  },
  watch: {
    locationId: function(newSchool, oldSchool) {
      if (newSchool !== null && newSchool !== oldSchool) {
        this.$store.dispatch("fetchRequestHistory", newSchool);
      }
    }
  },
  created() {
    this.$store.commit("setRequestHistoryFilter", this.searchString);

    if (this.locationId != null) {
      this.$store.dispatch("fetchRequestHistory", this.locationId);
    }
  },
  beforeDestroy() {
    this.$store.commit("setRequestHistoryFilter", "");
  },
  mixins: [searchDebounce],
  methods: {
    formatTimeDate(dateStr) {
      return timeDate(dateStr);
    },
    formatDate(dateStr) {
      return date(dateStr);
    },
    isApp(item) {
      return item.application != null;
    },
    isExpanded(index) {
      return this.expandedPanelIndex === index;
    }
  }
};
</script>

<style lang="scss" scoped>
#msa-app.mobile {
  #request-history--card-expansion-list {
    .v-expansion-panels {
      border-radius: 0;
    }

    .v-expansion-panel-header {
      padding-left: 9px;
    }

    .v-expansion-panel:before {
      box-shadow: none;
    }
  }
}

.v-expansion-panel-header {
  border-bottom: 1px solid $ads-light-20;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 9rem;
  position: relative;
}

.flex-fluid-clipped {
  flex-grow: 2;
  flex-shrink: 1;
  overflow: hidden;

  div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

div.v-expansion-panel:not(.v-item--active):last-of-type {
  border-bottom: 1px solid $ads-light-20;
}
</style>
